<template>
  <div class="couponCard">
    <div class="cardTop">
      <span>{{ data.mclvname }}</span>
    </div>
    <div class="cardConcent">
      <div class="presentPrice"><span>￥</span>{{ data.mclprice }}</div>
      <div class="originalPrice">￥{{ data.originPrice }}</div>
      <div class="line"></div>
      <div class="details">
        <div>有效期{{ data.mclshowtime }}天</div>
        <div class="det" v-if="data.mclbuytime != data.mclshowtime">
          送{{ data.mclbuytime - data.mclshowtime }}天
        </div>
      </div>
      <div class="introduce" v-html="data.Mlvremark"></div>
      <div class="purchaseBtn">
        <a-button @click="buyMember(data.mcid)" type="primary"  block>
          ￥{{ data.mclprice }} 立即续费
        </a-button>
      </div>
    </div>
  </div>
</template>
<script>
import wxPayModal from "@/components/wxPayModal";
import { payMember } from '@/api/pay'
export default {
  components: {
    // wxPayModal,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    buyMember(mcid) {
      console.log(mcid)
      let _this = this;
      let parameter = {
					// xeucode:10086688,
					mcid:mcid,
          paytype:2,
				}

				payMember(parameter).then(data => {
          console.log(data)
          
      _this.$dialog(
        wxPayModal,
        // component props
        {
          payData: data.data,
          on: {
            ok() {
              console.log("modal ok 回调");
              console.log(this)
            },
            cancel() {
              console.log("cancel 回调");
            },
            close() {
              console.log("modal close 回调");
              // console.log(this.close())
            },
          },
        },
        // modal props
        {
          width: 400,
          footer:"",//确定取消不要
          centered: true,
          maskClosable: false,
        }
      );
				}).catch(() => {
				
				
				})
      return;
      
    },
  },
};
</script>
<style lang="less" scoped>
.couponCard {
  width: 280px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: -1px 3px 9px 0px #d8d8d8;
  line-height: 1;
  .cardTop {
    padding: 24px 0;
    width: 100%;
    font-size: 30px;
    font-weight: bold;
    color: #9a6e42;
    text-align: center;
    background-image: linear-gradient(to right, #eac59b, #feebd1);
  }
  .cardConcent {
    // height: 226px;
    padding: 36px 22px 18px;
    background: #ffffff;
    text-align: center;
    .presentPrice {
      color: #fc592d;
      font-size: 60px;
      font-weight: bold;
      line-height: 46px;
      span {
        font-size: 18px;
      }
    }
    .originalPrice {
      color: #666666;
      font-size: 18px;
      text-decoration: line-through;
    }
    .line {
      width: 100%;
      border-top: dashed 2px #bec4d4;
      margin: 20px 0;
    }
    .details {
      font-size: 14px;
      height: 60px;
      line-height: 20px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: column;
      .det{
        color: #fb5a2c;
        font-weight: bold;
      }
    }
    .purchaseBtn {
      margin-top: 20px;
      .ant-btn {
        background-image: linear-gradient(to bottom right, #fdead1, #ebc79c);
        border: none;
        font-size: 16px;
        height: 40px;
        color: #333333;
        border-radius: 20px;
        line-height: 40px;
        &:hover {
          // background-image: linear-gradient(to bottom right, #fae1c1, #f5b464);
          color: #fff;
        }
      }
    }
  }
}
.introduce{
  text-align: left;
  line-height: 20px;
  color: #a6aec5;
  
}
p{
    margin-bottom:0px;
    
  }
</style>