<template>
  <div>

    <h2><strong>我的会员</strong></h2>
    <user-info></user-info>
    <coupon-list></coupon-list>
  
  </div>
</template>
<script>

import couponList from "./components/couponList.vue";
import userInfo from "./components/userInfo.vue";
export default {
  components: {
    couponList,
    userInfo
  },

  data() {
    return {};
  },

  methods: {
   
  },
};
</script>
