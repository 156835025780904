<template>



    <div class="wrap" :class="member.memberState==true?'':'gray'">
      <div class="memberMessage">
        <div class="flex">
          <div class="Gradebox" >
            <div class="GradeAvatar">
              <div class="t-icon " :class="member.memberState==true?'t-icon-VIP':'t-icon-VIP-copy'"></div>
            </div>
            <div class="GradeTxt">
              <div class="tname">{{nickname}}</div>
              <div class="ttime">会员到期时间：{{member.meberEndTime}}</div>
            </div>
          </div>
          <div class="couponNum">
            <div><i class="iconfont iconyouhuiquan"></i>可用优惠券：<span>{{couponAmount}}张</span></div>
          </div>
        </div>
      </div>
    </div>

  

</template>
<script>
import { mapGetters } from 'vuex'


export default {

  computed: {
    ...mapGetters(['nickname', 'member', 'couponAmount'])
  },
  data() {
    return {};
  },

  methods: {
   
  },
};
</script>
<style lang="less" scoped>
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.gray{
  .memberMessage{
    background:url(../../../assets/img/merberbg.png) no-repeat;
    background-size: 100% 100%;
    .GradeAvatar {
      background-image: linear-gradient(to right, #403f4f, #403f4f);
      border: 1px solid #403f4f;
    }
    .GradeTxt {
      color: #cfced3;
    }
    .couponNum {
      background: #403f4f;
      color: #cfced3;
      &:hover{
        color: #403f4f;
        background:#cfced3;
      }
    }
  }
}
.memberMessage {
  width: 960px;
  padding: 32px 22px;
  background-image: linear-gradient(to right, #fdebd3, #eac59b);
  border-radius: 10px;
  margin: 0 auto;
  
}
.Gradebox {
  display: flex;
  align-items: center;
}
.GradeAvatar {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-image: linear-gradient(to right, #fdedd3, #ebc69c);
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 18px;
  .t-icon {
    width: 36px;
    height: 36px;
    background-size: 100% 100%;
  }
}
.GradeTxt {
  line-height: 30px;
  .tname {
    font-size: 22px;
  }
  .ttiem {
    font-size: 12px;
  }
}
.couponNum {
  background: #fcebd1;
  padding: 4px 12px;
  border-radius: 10px;
  color: #9a6e42;
  font-size: 16px;
  cursor: pointer;
  transition:all .2s;
  &:hover{
        color: #fcebd1;
        background:#9a6e42;
  }
  span{
    font-weight: bold;
    font-size: 15px;
  }
}
</style>
<style lang="less" scoped>
h2{
    border-bottom: 1px solid #ddd;
    padding-bottom:20px;
    strong{
        position: relative;
        &:after {
            content: '';
            position: absolute;
            height: 3px;
            width: 120px;
            left: 0;
            bottom: -25px;
            background-image: linear-gradient(to right, #0049ee , #36bcf0);
    
        }
    }
}
</style>