<template>
  <div class="wxpay">
    <h2 class="">付款</h2>
    <div class="Price">
      <div>结算金额</div>
      <div class="payNum"><span>￥</span>{{payData.price}}</div>
    </div>
    <div class="payEwm">
      <div class="border_corner border_corner_left_top"></div>
      <div class="border_corner border_corner_right_top"></div>
      <div class="border_corner border_corner_left_bottom"></div>
      <div class="border_corner border_corner_right_bottom"></div>
      <div class="qr-code">
        <vue-qr :text="payData.code_url" :logoSrc='logoSrc' :logoScale="0.2" :size="600" ></vue-qr>
      </div>
    </div>
    <div class="remarks">
      <a-icon
        type="wechat"
        style="font-size: 22px; margin-right: 6px; color: #25c82f"
      />微信扫一扫支付
    </div>
  </div>
</template>
<script>
import VueQr from "vue-qr";
import store from '@/store'
import { getOrderstatus,getworkno } from "@/api/pay";
export default {
  components: {
    VueQr,
  },
  props: {
    payData: {
      type: Object,
      default: null,
    },
    openDesigner:{
      type:Function,
      default:function(){}
    }
  },
  computed: {},
  data() {
    return {
      timers: "",
      logoSrc:require("../../assets/icon.svg")
    };
  },
  mounted() {
    // this.$attrs.on.close()
    this.getOrderstate();
  },
  // 还有一步是最容易被忽略的 跳转页面的时候也应该关闭定时器
  beforeDestroy() {
    if (this.timers) {
      clearInterval(this.timers); //关闭
    }
  },
  methods: {
    // 调用一个判断支付是否成功的接口
    getOrderstate() {
      let self = this;
      let num = 0;
      self.timers = setInterval(() => {
        num++;
        console.log(num);
        let obj = {
          odno: self.payData.orderno,  
        };
        getOrderstatus(obj).then((res) => {
          if (res.data.status == 1) {
            store.dispatch('GetInfo').then(() => {
                           
                        })
            getworkno({odno:self.payData.orderno}).then(Data => {
					console.log(Data)
          this.openDesigner(Data.data.workno,Data.data.worktype)
				})
            
            // self.$router.go(0)
            clearInterval(this.timers); //关闭定时器
          }
        });
        if (num == 500) {
          //判断num++到500的情况下用户还没有支付则自动关闭定时器和二维码
          // this.boxShow = false
          clearInterval(this.timers);
        }
      }, 1500);
    },
  },
};
</script>
<style lang="less" scoped>
.main {
  width: 100%;
  height: 550px;
  border: 2px solid #1c93cb;
  border-radius: 4px;
}
.border_corner {
  z-index: 2500;
  position: absolute;
  width: 24px;
  height: 24px;
  background: rgba(0, 0, 0, 0);
  border: 3px solid #999999;
}
.border_corner_left_top {
  top: -2px;
  left: -2px;
  border-right: none;
  border-bottom: none;
  border-top-left-radius: 4px;
}
.border_corner_right_top {
  top: -2px;
  right: -3px;
  border-left: none;
  border-bottom: none;
  border-top-right-radius: 4px;
}
.border_corner_left_bottom {
  bottom: -1px;
  left: -2px;
  border-right: none;
  border-top: none;
  border-bottom-left-radius: 4px;
}
.border_corner_right_bottom {
  bottom: -2px;
  right: -3px;
  border-left: none;
  border-top: none;
  border-bottom-right-radius: 4px;
}
h2 {
  font-weight: bold;
  margin-bottom: 20px;
}
.Price {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  line-height: 0;
  .payNum {
    color: #fb582d;
    font-size: 30px;
    font-weight: bold;
    margin-left: 8px;
    span {
      font-size: 14px;
      margin-right: 4px;
    }
  }
}
.payEwm {
  border: 1px solid #e5e5e5;
  position: relative;
  margin: 30px auto;
  // padding: 14px;
  width: 180px;
  height: 180px;
  .qr-code {
    width: 100%;
    img {
      width: 100%;
    }
  }
}
.remarks {
  display: flex;
  justify-content: center;
  color: #000;
}
</style>