<template>
    <div class="couponlist">
        <coupon-card v-for="(card,index) in memberList" :data='card' :key="index"></coupon-card>
    </div>
</template>
<script>
import { getSmlist } from "@/api/manage";
import couponCard from "./couponCard.vue";

export default {
  components: {
    couponCard,
  },
  data() {
    return {
      memberList:[],
    };
  },
  mounted(){
    this.memberPackage()
  },
  methods: {
   memberPackage(){
     getSmlist().then(({ data }) => {
       console.log(data)
       this.memberList=data
      })
   }
  },
};
</script>
<style scoped>
.couponlist{
    margin: 50px auto 0;
    width: 960px;
    display: flex;
    justify-content: space-between;
}
</style>